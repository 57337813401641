import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToastOptions } from "../models/toast-options.model";
import { ToastItem } from "../models/toast-item.model";
import { Guid } from "guid-typescript";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private readonly onOpen = new Subject<any>();
  $onOpen = this.onOpen.asObservable();
  private readonly onClear = new Subject<Guid>();
  $onClear = this.onClear.asObservable();
  toastSubject = new Subject<ToastOptions>();
  $toastObservable = this.toastSubject.asObservable();

  public toastList: ToastItem<any>[] = [];

  // onOpenToast(toastOptions: ToastOptions) {
  //   this.toastSubject.next(toastOptions);
  // }

  clear(toastId: Guid) {
    this.onClear.next(toastId);
    this.__removeToastId(toastId);
  }

  open<T>(toast: ToastItem<T>) {
    this.onOpen.next(toast);
    this.toastList.push(toast);
  }

  __removeToastId(toastId: Guid) {
    this.toastList = this.toastList.filter(toast => !toast.id.equals(toastId));
  }
}
