import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ModalItem } from "../models/modal-item.model";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  onOpen = new Subject<any>();
  $onOpen = this.onOpen.asObservable();

  onClose = new Subject<void>();
  $onClose = this.onClose.asObservable();

  open<T>(modal: ModalItem<T>) {
    this.onOpen.next(modal);
  }

  close() {
    this.onClose.next();
  }
}
