import { Type } from "@angular/core";
import { ModalData } from "./modal-data.model";

export class ModalItem<T> {
  constructor(
    public component: Type<any>,
    public onComponentInit: (component: T) => void,
    public data: ModalData
  ) { }
}

